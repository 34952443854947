import React, { Component } from 'react';
import Slide from 'react-reveal/Slide';
import emailjs from 'emailjs-com';

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      message: '',
      reason: '',
      errorName: true,
      errorEmail: true,
      errorMessage: true,
      errorReason: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidUpdate() {
    if (!this.state.errorName && !this.state.errorEmail && !this.state.errorMessage && !this.state.errorReason) {
      console.log(`Message received, ready to send: ${this.state.name}, ${this.state.email}, ${this.state.reason}, ${this.state.message}`);
      this.handleSubmit();
    }
  }

  render() {
    return (
      <div>
      <Slide top>
        <h2 className="pageTitle pageTitleMrg">Contact</h2>

        <div className="pageOval">
        </div>
      </Slide>

      <section className="contactForm">
        <form>
          <section id="contactReason">
            <span className="cntError" id="rsnErr">a reason for your email must be selected</span>
            <h3>Reason for contact:</h3>
            <input type="radio" name="reason" id="cQst" value="questions" />
              <label htmlFor="cQst" className="contactBtn">Questions</label>
            <input type="radio" name="reason" id="cCmt" value="comments" />
              <label className="contactBtn" htmlFor="cCmt">Comments</label>
            <input type="radio" name="reason" id="cBk" value="booking" />
              <label className="contactBtn" htmlFor="cBk">Booking</label>
          </section>

          <section className="emailForm">
            <div id="emailFormBg">
              <h3>Email me:</h3>
              <span className="cntError" id="nameErr">name is required and may be no longer than 50 characters</span>
              <label htmlFor="name">Name</label>
              <input id="name" type="text" name="name" maxLength="50" placeholder="Name" required />
              <span className="cntError" id="emailErr">a valid email is required</span>
              <label htmlFor="email">Email</label>
              <input id="email" name="email" type="email" placeholder="Email" required />
              <span className="cntError" id="msgErr">message is required</span>
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" placeholder ="Message"></textarea>
              <button type="button" id="contactFormSend"
                      onClick={this.handleAuth}>
                      Send
              </button>
            </div>
          </section>
        </form>
      </section>

      <section className="cta" id="socialMedia">
      <p><span className="ctaSpan">You can find me on social media too! </span>
      Get in the know, stay up to date:</p>
        <ul>
          <li><img src="images/icons/fb_icon.svg" alt="facebook icon"/></li>
          <li><img src="images/icons/insta_icon.svg" alt="instagram icon"/></li>
          <li><img src="images/icons/mm_icon.svg" alt="model mayhem icon"/></li>
        </ul>
        <img src="images/icons/bg_longleaf.svg" className="bgcta" alt="Sarah Jain leaf logo"/>
      </section>
      </div>
    )
  }

  handleChange(e) {
    this.setState({
      message: e.target.value
    });
  }

  handleAuth() {
    //regex
    const nameRegEx = /^(?:\w[.\'\-]? ?){1,50}$/;
    const emailRegEx = /^[_a-zA-Z0-9\\-]+(\.[_a-zA-Z0-9\\-]+)*@[a-zA-Z0-9\\-]+(\.[a-zA-Z0-9\\-]+)*(\.[a-z]{2,6})$/;
    const emptyRegEx = /^\s*$/;

    //pull values AND clean them (do not read)
    const nameDirty = document.getElementById('name');
    const emailDirty = document.getElementById('email');
    const messageDirty = document.getElementById('message');
    const reason = document.getElementsByName('reason');
    const labelVal = document.getElementsByTagName('label');
    let reasonVal;

    //clean inputs, do not read them yet
    let name = nameDirty.value.replace(/(\(*)(\)*)(=*)(!*)(\?*)/g, "");
    //name = encodeURI(name);
    let email = emailDirty.value.replace(/( *)?(!*)?(\?*)?(=*)?(\(*)?(\)*)?/g, "");
    //email = encodeURI(email);
    let message = messageDirty.value.replace(/(\(*)(\)*)(=*)(\[*)(\]*)(\{*)(\}*)(>*)(<*)(\|*)/g, "");


    //check if name passes regex
    if (!nameRegEx.test(name)) {
      this.setState({
        errorName: true,
        name: ''
      }, () => this.handleError());
    } else {
      this.setState({
        errorName: false,
        name: name
      }, () => this.handleError());
    }

    //check if email passes regex
    if (!emailRegEx.test(email)) {
      this.setState({
        errorEmail: true,
        email: ''
      }, () => this.handleError());
    } else {
      this.setState({
        errorEmail: false,
        email: email
      }, () => this.handleError());
    }

    //check if message is filled
    if (emptyRegEx.test(message)) {
      this.setState({
        errorMessage: true,
        message: ''
      }, () => this.handleError());
    } else {
      this.setState({
        errorMessage: false,
        message: message
      }, () => this.handleError());
    }

    //check if reason is selected
    for (var i=0; i<reason.length; i++) {
      if (reason[i].checked) {
        reasonVal = labelVal[i].innerHTML;
      }
    }

    if (reasonVal) {
      this.setState({
        errorReason: false,
        reason: reasonVal
      }, () => this.handleError());
    } else {
      this.setState({
        errorReason: true,
        reason: ''
      }, () => this.handleError());

    }
  }

  handleError() {
    console.log("handle errors running");
    const name = document.getElementById("name");
    const email = document.getElementById("email");
    const message = document.getElementById("message");
    const reason = document.getElementById('contactReason');
    const errMsg = document.getElementsByClassName('cntError');

    if(this.state.errorName) {
      name.style.border = "3px solid #FF3352";
      name.style.backgroundColor = "#FDDBE0";
      errMsg[1].style.display = "block";
    } else {
      name.style.border = "1px #FFF solid";
      name.style.backgroundColor = "#FFF";
      errMsg[1].style.display = "none";
    }

    if(this.state.errorEmail) {
      email.style.border = "3px solid #FF3352";
      email.style.backgroundColor = "#FDDBE0";
      errMsg[2].style.display = "block";
    } else {
      email.style.border = "1px #FFF solid";
      email.style.backgroundColor = "#FFF";
      errMsg[2].style.display = "none";
    }

    if(this.state.errorMessage) {
      message.style.border = "3px solid #FF3352";
      message.style.backgroundColor = "#FDDBE0";
      errMsg[3].style.display = "block";
    } else {
      message.style.border = "1px #FFF solid";
      message.style.backgroundColor = "#FFF";
      errMsg[3].style.display = "none";
    }

    if(this.state.errorReason) {
      reason.style.border = "3px solid #FF3352";
      errMsg[0].style.display = "block";
    } else {
      reason.style.border = "none";
      errMsg[0].style.display = "none";
    }
  }

  handleSubmit() {
    this.setState({
      name: '',
      email: '',
      reason: '',
      message: '',
      errorName: true,
      errorEmail: true,
      errorReason: true,
      errorMessage: true
    });
    this.props.showModal('open', 'contact')
  }

}

export default ContactPage;

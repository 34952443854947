import React, { Component } from 'react';
import SocialNav from '../../nav/socialNav';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      message: '',
      reason: 'booking',
      errorName: true,
      errorEmail: true,
      errorMessage: true
    };
    this.handleAuth = this.handleAuth.bind(this);
  }

  componentDidUpdate() {

    if(!this.state.errorName &&
       !this.state.errorEmail &&
       !this.state.errorMessage){
      console.log('run submission everything checks out!');
      this.handleSubmit();
    } else {
      console.log('errors! do not submit!');

    }

  }

  render() {
    if(this.props.fillModal.empty) {
      return(
        <div className="modalWrapper">
          <section id="modal">
          <div className="modalHeader">
              <img src="images/icons/close_icon.svg" className="closeBtn" alt="close window button" title="close button"
                  onClick={() => this.props.showModal('close', 'na')}
              />
              <h2 id="modalHead">Modal Header</h2>
            </div>
            <section id="modalBody">
            </section>
          </section>
        </div>
      );
    } else if (this.props.fillModal.thankYou) {
      return(
        <div className="modalWrapper">
          <section id="modal">
          <div className="modalHeader">
              <img src="images/icons/close_icon.svg" className="closeBtn" alt="close window button" title="close button"
                  onClick={() => this.props.showModal('close', 'na')}
              />
              <h2 id="modalHead">Thank You</h2>
            </div>
            <section id="modalBody">
              <p id="thankYouMsg">Your message has been sent. I will be in touch through email soon!</p>
              <section id="modalSocial">
                <h3>In the mean time, feel free to check out my social media!</h3>
                <SocialNav addClass="socialNavTy"/>
              </section>
              <button onClick={() => this.props.showModal('close', 'na')}>
                ok
              </button>
            </section>
          </section>
        </div>
      );
    } else if (this.props.fillModal.form) {
      return(
        <div className="modalWrapper">
          <section id="modal">
            <div className="modalHeader">
              <img src="images/icons/close_icon.svg" className="closeBtn" alt="close window button" title="close button"
                  onClick={() => this.props.showModal('close', 'na')}
              />
              <h2 id="modalHead">Request A Booking</h2>
            </div>
            <section id="modalBody" className="contactForm">
                <p id="advised"><u>*please be advised</u>: I will  only travel to locations that allow the carry and use of
                  medical marijuana.</p>
                <section id="bookingForm" className="emailForm">
                  <form id="modalForm">
                    <h3>selected date: <span id="dateSelect">{this.props.bookingDate}</span></h3>
                    <span className="cntError" id="bknameErr">name is required and may be no longer than 50 characters</span>
                    <label htmlFor="name">Name</label>
                    <input id="name" type="text" name="name" placeholder="Name" />
                    <span className="cntError" id="bkemailErr">a valid email is required</span>
                    <label htmlFor="email">Email</label>
                    <input id="email" name="email" type="email" placeholder="Email" />
                    <span className="cntError" id="bkmsgErr">message is required</span>
                    <label htmlFor="message">Message</label>
                    <textarea id="message" name="message" placeholder ="Please include information about the booking"></textarea>
                    <button type="button" id="contactFormSend"
                            //onClick={() => this.props.showModal('open', 'contact')}>
                            onClick={this.handleAuth}>
                            Send
                    </button>
                  </form>
                </section>
            </section>
          </section>
        </div>
      );
    } else if (this.props.fillModal.faq) {
      return(
        <div className="modalWrapper">
          <section id="modal">
            <div className="modalHeader">
              <img src="images/icons/close_icon.svg" className="closeBtn" alt="close window button" title="close button"
                  onClick={() => this.props.showModal('close', 'na')}
              />
              <h2 id="modalHead">FAQ</h2>
            </div>
            <section id="modalBody">
              <ol>
                <li>FAQ number one goes here</li>
                  <ul>number one follow up answer goes here</ul>
                <li>FAQ number one goes here</li>
                  <ul>number one follow up answer goes here</ul>
                <li>FAQ number one goes here</li>
                  <ul>number one follow up answer goes here</ul>
                <li>FAQ number one goes here</li>
                  <ul>number one follow up answer goes here</ul>
                <li>FAQ number one goes here</li>
                  <ul>number one follow up answer goes here</ul>
                <li>FAQ number one goes here</li>
                  <ul>number one follow up answer goes here</ul>
                <li>FAQ number one goes here</li>
                  <ul>number one follow up answer goes here</ul>
                <li>FAQ number one goes here</li>
                  <ul>number one follow up answer goes here</ul>
                <li>FAQ number one goes here</li>
                  <ul>number one follow up answer goes here</ul>
                <li>FAQ number one goes here</li>
                  <ul>number one follow up answer goes here</ul>
              </ol>
              <button id="faqOk" onClick={() => this.props.showModal('close', 'na')}>
                ok
              </button>
            </section>
          </section>
        </div>
      );
    }

  }

  handleAuth () {
    console.log("handle auth running");
    //regex
    const nameRegEx = /^(?:\w[.\'\-]? ?){1,50}$/;
    const emailRegEx = /^[_a-zA-Z0-9\\-]+(\.[_a-zA-Z0-9\\-]+)*@[a-zA-Z0-9\\-]+(\.[a-zA-Z0-9\\-]+)*(\.[a-z]{2,6})$/;
    const emptyRegEx = /^\s*$/;

    //pull values AND clean them (do not read)
    const nameDirty = document.getElementById('name');
    const emailDirty = document.getElementById('email');
    const messageDirty = document.getElementById('message');

    //clean inputs, do not read them yet
    let name = nameDirty.value.replace(/(\(*)(\)*)(=*)(!*)(\?*)/g, "");
    //name = encodeURI(name);
    let email = emailDirty.value.replace(/( *)?(!*)?(\?*)?(=*)?(\(*)?(\)*)?/g, "");
    //email = encodeURI(email);
    let message = messageDirty.value.replace(/(\(*)(\)*)(=*)(\[*)(\]*)(\{*)(\}*)(\>*)(\<*)(\|*)/g, "");

    //check if name passes regex
    if (!nameRegEx.test(name)) {
      this.setState({
        errorName: true
      }, () => this.handleError());
    } else {
      this.setState({
        errorName: false,
        name: name
      }, () => this.handleError());
    }

    //check if email passes regex
    if (!emailRegEx.test(email)) {
      this.setState({
        errorEmail: true
      }, () => this.handleError());
    } else {
      this.setState({
        errorEmail: false,
        email: email
      }, () => this.handleError());
    }

    //check if message is filled
    if (emptyRegEx.test(message)) {
      this.setState({
        errorMessage: true
      }, () => this.handleError());
    } else {
      this.setState({
        errorMessage: false,
        message: message
      }, () => this.handleError());
    }
  }

  handleError () {
    console.log("now handling errors");

    const name = document.getElementById("name");
    const email = document.getElementById("email");
    const message = document.getElementById("message");
    const errMsg = document.getElementsByClassName('cntError');

    if(this.state.errorName) {
      name.style.border = "3px solid #FF3352";
      name.style.backgroundColor = "#FDDBE0";
      errMsg[0].style.display = "block";
    } else {
      name.style.border = "1px #FFF solid";
      name.style.backgroundColor = "#FFF";
      errMsg[0].style.display = "none";
    }

    if(this.state.errorEmail) {
      email.style.border = "3px solid #FF3352";
      email.style.backgroundColor = "#FDDBE0";
      errMsg[1].style.display = "block";
    } else {
      email.style.border = "1px #FFF solid";
      email.style.backgroundColor = "#FFF";
      errMsg[1].style.display = "none";
    }

    if(this.state.errorMessage) {
      message.style.border = "3px solid #FF3352";
      message.style.backgroundColor = "#FDDBE0";
      errMsg[2].style.display = "block";
    } else {
      message.style.border = "1px #FFF solid";
      message.style.backgroundColor = "#FFF";
      errMsg[2].style.display = "none";
    }
  }

  handleSubmit() {

    this.setState({
      email: '',
      name: '',
      message: '',
      errorName: true,
      errorEmail: true,
      errorMessage: true
    });

    this.props.showModal('open', 'contact');
  }

}

export default Modal;
